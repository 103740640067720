<template>
  <div class="register-select app_content">
    <div class="warp_body">
      <div class="vanPopupDiv">

        <van-field
            label="评分"
            readonly
        >
          <template #input>
            <van-rate v-model="evaluate.score" :count="5" allow-half/>
            <span style="margin-left: 20px">{{ evaluate.score == 0 ? '' : evaluate.score + '分' }}</span>
          </template>
        </van-field>
        <!--          <p>-->
        <!--            <van-rate v-model="evaluate.score" :count="5" allow-half/>-->
        <!--            <span style="margin-left: 20px">{{ evaluate.score == 0 ? '' : evaluate.score + '分' }}</span>-->
        <!--          </p>-->
        <van-field
            v-model="evaluate.desc"
            rows="2"
            autosize
            maxlength="120"
            border="true"
            label="评语"
            type="textarea"
            placeholder="请输入评语"
            show-word-limit
        />
        <p style="width: 100%">
          <van-button round type="primary" size="large" style="width: 90%" @click="submitEvaluate()">确定
          </van-button>
        </p>
      </div>


    </div>

  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API_Order from "../api/OrderReq";
import req from "../request/request";
import router from "../router";
import {useRoute} from "vue-router";
import API_ORDER from "@/api/Order";


export default {
  name: 'OrderEvaluate',
  setup() {
    onBeforeMount(() => {
      const route = useRoute();
      data.orderId = route.params.orderId
    });
    const data = reactive({
          orderId: '',
          evaluate: {
            score: 0,
            desc: ''
          }
        },
    )

    const methodsMap = {
      submitEvaluate: () => {
        let reqJson = data.evaluate;
        reqJson.orderId = data.orderId
        API_Order.evaluate(reqJson);
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {
    .vanPopupDiv {

      p {
        text-align: center;
        padding-top: 10px;
      }
    }
  }
}
</style>
